<template>
  <div>
    <p class="text-xs font-semibold mb-2">Default task type</p>
    <TaskTypeSelectPopover
      disable-setting
      :parent-id="parentId"
      :source="source"
      :selected-task-type-id="selectedTaskType?.id"
      :show-header="false"
      :accept-levels="[TaskLevel.MODULE, TaskLevel.TASK]"
      :disabled="
        !can('dashboard.settings_packs.manage_tasktypes_custom_fields')
      "
      @change="onChange"
    >
      <div class="p-0.5">
        <UButton
          class="w-full flex items-center justify-between"
          color="gray"
          :disabled="
            !can('dashboard.settings_packs.manage_tasktypes_custom_fields')
          "
        >
          <div class="flex items-center gap-2">
            <TaskTypeIcon
              v-if="selectedTaskType"
              :background="selectedTaskType?.background"
              :icon="selectedTaskType?.icon"
              size="xs"
            />
            <div>{{ selectedTaskType?.name }}</div>
          </div>
          <Icon class="text-gray-900" name="heroicons:chevron-down" />
        </UButton>
      </div>
    </TaskTypeSelectPopover>
  </div>
</template>

<script lang="ts" setup>
import type { Reference } from '@apollo/client'
import { TaskLevel } from '#task/constant'
import type { BaseTaskType } from '#task-type/types'

const props = defineProps({
  parentId: {
    type: String,
    required: true,
  },
  source: {
    type: String as PropType<MorphSource>,
    required: true,
  },
})

const toast = useToast()
const { client } = useApolloClient()
const { boardData } = useBoardSharedState()
const { can } = useBoardAbility()

const selectedTaskType = computed(() => {
  return boardData.value.default?.taskType || {}
})

const onChange = async (taskType: BaseTaskType) => {
  const { mutate, error } = useUpdateBoardMutation()
  await mutate({
    input: { id: props.parentId, defaultTaskType: taskType.id },
  })
  if (error.value) {
    return handleSingleGraphQLError(error.value)
  }

  toast.add({
    color: 'green',
    description: 'Set task type default successfully',
  })
  client.cache.modify({
    id: 'ROOT_QUERY',
    fields: {
      boardData: (
        existingData: Reference,
        { readField, toReference, storeFieldName }
      ) => {
        const storeField = extractStoreFieldName<{ boardId: string }>(
          storeFieldName
        )
        if (storeField.boardId !== props.parentId) {
          return existingData
        }

        const existingDefault = readField('default', existingData) as Reference
        return {
          ...existingData,
          default: {
            ...existingDefault,
            taskType: toReference(taskType.id),
          },
        }
      },
    },
  })
}
</script>
